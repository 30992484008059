<template>
  <div class="wrapper">
    <div class="master">
      <b-skeleton-wrapper class="h-100" :loading="masterLoading">
        <template #loading>
          <b-card class="h-100" no-body>
            <b-card-header>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-header>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
          </b-card>
        </template>
        <slot name="master"><h1>Master</h1></slot>
      </b-skeleton-wrapper>
    </div>
    <div class="detail">
      <b-skeleton-wrapper class="h-100" :loading="detailLoading">
        <template #loading>
          <b-card class="h-100" no-body>
            <b-card-header>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-header>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
            <b-card-body>
              <b-skeleton />
              <b-skeleton width="55%"></b-skeleton>
            </b-card-body>
          </b-card>
        </template>
        <slot name="detail"><h1>detail</h1></slot>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    masterLoading: { type: Boolean, default: false },
    detailLoading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.wrapper .master {
  width: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 7px;
}
.wrapper .detail {
  width: 100%;
  overflow-y: auto;
  margin: 7px;
}
</style>
