<template>
  <b-btn
    variant="primary"
    class="mx-auto"
    v-b-tooltip.hover
    size="sm"
    :title="$t('comments.button')"
    v-b-modal="id"
    id="feedback-modal-btn"
    v-if="Features.length > 0 || hasComments"
  >
    <b-icon-chat-square-fill v-if="hasUnresolved" variant="warning" />
    <b-icon-chat-square v-else variant="light" />
    <b-modal
      :id="id"
      :title="$t('comments.title') + costCalculationName"
      centered
      scrollable
      header-bg-variant="primary"
      header-text-variant="light"
      size="lg"
      @show="getComments"
    >
      <b-overlay :show="busy" :opacity="1">
        <b-row>
          <b-col cols="2"> {{ $t("comments.body") }} </b-col>
          <b-col v-if="comments.length != 0">
            <comment
              v-for="i in comments"
              :comment="i"
              :costCalculationStatus="costCalculationStatus"
              v-bind:key="i.idComment"
              @selectReply="getReplyComment"
            >
            </comment>
          </b-col>
          <b-col class="text-center" v-else>
            {{ $t("comments.no-comments") }}</b-col
          >
        </b-row>
        <div class="text-right"></div>

        <template #overlay>
          <simple-loader />
        </template>
      </b-overlay>
      <template #modal-footer>
        <b-button-group class="mx-1" v-if="costCalculationOwner === Name">
          <SubmittingCase
            :disabled="
              costCalculationStatus != 1 &&
              costCalculationStatus != 3 &&
              costCalculationStatus != 6
            "
            @submitted="reloadPage"
          />
        </b-button-group>
        <b-button-group class="mx-1" v-if="IsReviewer || IsAdminCustomer">
          <AcceptingCase
            class="mx-1"
            :disabled="costCalculationStatus != 4"
            @accepted="reloadPage"
          />
          <ReturningCase
            class="mx-1"
            :disabled="costCalculationStatus != 4"
            @returned="reloadPage"
          />
        </b-button-group>
        <b-btn
          size="sm"
          variant="primary"
          v-b-modal="'new-comment-modal'"
          v-b-tooltip.hover
          :title="$t('comments.new-comment')"
          v-if="Features.length > 0 && costCalculationStatus != 2"
          ><b-icon-plus
        /></b-btn>
      </template>
    </b-modal>
    <new-comment :idCostCalculation="idCostCalculation" />
    <reply-comment
      :idCostCalculation="idCostCalculation"
      :parentComment="selectedReply"
    />
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import comment from "./comment.vue";
import NewComment from "./new-comment.vue";
import ReplyComment from "./reply-comment.vue";
import AcceptingCase from "@/components/HeaderCase/AcceptingCase.vue";
import ReturningCase from "@/components/HeaderCase/ReturningCase.vue";
import SubmittingCase from "@/components/HeaderCase/SubmittingCase.vue";

export default {
  components: {
    comment,
    NewComment,
    ReplyComment,
    AcceptingCase,
    ReturningCase,
    SubmittingCase,
  },
  props: {
    idCostCalculation: {
      type: Number,
      required: true,
    },
    costCalculationName: {
      type: String,
      required: true,
    },
    hasUnresolved: {
      type: Boolean,
      required: true,
    },
    hasComments: {
      type: Boolean,
      required: true,
    },
    costCalculationStatus: {
      type: Number,
      required: true,
    },
    costCalculationOwner: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      baseId: "feedback-modal-",
      id: "feedback-modal-",
      selectedReply: {},
    };
  },
  methods: {
    ...mapActions("CostCalculationComments", ["getCostCalculationComments"]),
    getReplyComment(replyComment) {
      this.selectedReply = replyComment;
      this.$bvModal.show("reply-comment-modal");
    },
    getComments() {
      this.busy = true;
      this.getCostCalculationComments({
        idCostCalculation: this.idCostCalculation,
      })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
    reloadPage() {
      this.$router.push("/");
      location.reload();
    },
  },
  computed: {
    ...mapGetters("CostCalculationComments", ["comments"]),
    ...mapGetters({
      IsReviewer: "GET_ISREVIEWER",
      IsAdminCustomer: "GET_ISADMINCUSTOMER",
      Features: "GET_FEATURES",
      Name: "GET_NAME",
    }),
  },
  mounted() {
    this.id = this.baseId + this.idCostCalculation;
  },
};
</script>
