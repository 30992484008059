<template>
  <b-modal
    id="select-masterform"
    :title="$t('views.subCases.newSubcase')"
    @ok="onOK"
    @show="showInfo"
    @hidden="resetModal"
  >
    <b-overlay :show="busy">
      <b-form-group
        :label="$t('views.cases.masterForm')"
        label-for="NewSubcaseMasterForm"
        :invalid-feedback="$t('views.cases.masterFormRequired')"
        label-cols="4"
        :state="MasterFormState"
      >
        <b-form-select
          v-model="form.masterForm"
          required
          :state="MasterFormState"
          id="NewSubcaseMasterForm"
        >
          <template #first>
            <b-form-select-option :value="null" disabled style="opacity: 0.5">{{
              $t("views.cases.masterFormInstructions")
            }}</b-form-select-option>
          </template>
          <b-form-select-option
            id="select-1"
            v-for="(masterForm, index) in masterFormList"
            v-bind:key="index"
            :value="masterForm"
            >{{ masterForm.name }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Name"
        label-for="NewSubcaseName"
        :invalid-feedback="subcaseNameFeedback"
        label-cols="4"
        :state="CaseNameState"
      >
        <b-form-input
          :placeholder="$t('views.subCases.nameInstructions')"
          v-model="form.name"
          id="NewSubcaseName"
          required
          trim
          :state="CaseNameState"
          :formatter="formatter"
        ></b-form-input>
      </b-form-group>
      <template #overlay>
        <simple-loader />
      </template>
    </b-overlay>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button
          @click="$bvModal.hide('select-masterform')"
          id="NewSubcaseCancel"
          >{{ $t("general.cancel") }}</b-button
        ><b-button variant="primary" @click="onOK" id="NewSubcaseOk">{{
          $t("general.ok")
        }}</b-button></slot
      >
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const initForm = {
  masterForm: null,
  name: null,
};

const props = {
  idOrganizationalUnit: {
    required: true,
    type: Number,
  },
};
const data = () => {
  return {
    form: { ...initForm },
    busy: false,
    MasterFormState: null,
    CaseNameState: null,
    subcaseNameFeedback: null,
    caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
  };
};

const computed = {
  ...mapGetters("Cases", ["masterFormList"]),
};

const methods = {
  ...mapActions("Cases", {
    getMasterForms: "getMasterFormsInOU",
  }),
  formatter(value) {
    if (value.match(this.caseNameRegex)) {
      this.subcaseNameFeedback = this.$t("views.cases.notAllowedSymbols");
      this.CaseNameState = false;
    } else {
      this.CaseNameState = null;
    }
    return value.replace(this.caseNameRegex, "");
  },
  resetModal() {
    this.form = { ...initForm };
    this.MasterFormState = null;
    this.CaseNameState = null;
  },
  checkFormValidity() {
    let check = (this.MasterFormState = this.CaseNameState = true);
    if (!this.form.masterForm) this.MasterFormState = check = false;
    if (!this.form.name) {
      this.CaseNameState = check = false;
      this.subcaseNameFeedback = this.$t("views.cases.nameRequired");
    }
    return check;
  },
  onOK(oEvent) {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      oEvent.preventDefault();
      return;
    }
    this.$emit("selected", this.form);
  },
  showInfo() {
    this.busy = true;
    this.resetModal();
    this.getMasterForms({
      api: this.$api,
      session: this.$session,
      idOrganizationalUnit: this.idOrganizationalUnit,
    }).then(() => {
      this.busy = false;
    });
  },
};

export default {
  props,
  data,
  computed,
  methods,
};
</script>
