<template>
  <b-button
    :disabled="!subcase"
    variant="primary"
    v-b-tooltip.hover
    :title="$t('views.home.duplicateSubcase')"
    v-b-modal.duplicate-subcase
    id="CasesDuplicateSubcase"
  >
    <b-icon-files />
    <b-modal
      id="duplicate-subcase"
      ref="modal"
      :title="$t('views.home.duplicateSubcase')"
      centered
      @show="onShow"
      @hidden="onHidden"
      @ok="handleOk"
      :busy="busy"
    >
      <b-overlay :show="busy" spinner-variant="primary">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Subcase name"
            label-for="DuplicateSubcaseName"
            invalid-feedback="Name is required"
            :state="nameState"
            :disabled="busy"
          >
            <b-form-input
              id="DuplicateSubcaseName"
              v-model="name"
              :state="nameState"
              :disabled="busy"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="secondary"
          @click="cancel()"
          id="DuplicateSubcaseCancel"
        >
          {{ $t("general.cancel") }}
        </b-button>
        <b-button variant="primary" @click="ok()" id="DuplicateSubcaseOk">
          {{ $t("general.ok") }}
        </b-button>
      </template>
    </b-modal>
  </b-button>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    subcase: Object,
  },
  data() {
    return {
      name: "",
      nameState: null,
      busy: false,
    };
  },
  methods: {
    ...mapActions("Subcases", ["duplicate"]),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    onShow() {
      this.name = this.subcase.name;
    },
    onHidden() {
      this.resetModal();
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Call Duplicate service
      this.busy = true;
      this.duplicate({
        session: this.$session,
        api: this.$api,
        idSubcase: this.subcase.idSubcase,
        name: this.name,
      })
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide("duplicate-subcase");
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
};
</script>

<style></style>
