<template>
  <b-modal
    :title="$t('comments.reply-title')"
    id="reply-comment-modal"
    @show="clearComment"
    variant="primary"
  >
    {{ $t("comments.reply-to") }}:
    <b-card no-body class="my-2 pr-0">
      <b-card-body>
        <b-card-sub-title class="mb-1">
          {{
            `${parentComment.createdByName}, ${formatDate(
              parentComment.createdDate
            )}:`
          }}
        </b-card-sub-title>
        <div>
          <span style="white-space: pre">{{ parentComment.comment }}</span>
        </div>
      </b-card-body>
    </b-card>
    <b-form-group
      :label="$t('comments.button') + ':'"
      :state="isTextValid"
      :invalid-feedback="$t('comments.length-feedback')"
    >
      <b-textarea v-model="text" required :state="isTextValid" />
    </b-form-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="cancelComment" :disabled="busy">{{
          $t("general.cancel")
        }}</b-button
        ><b-button
          variant="primary"
          @click="validateComment"
          :disabled="busy"
          >{{ $t("general.ok") }}</b-button
        ></slot
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: {
    idCostCalculation: {
      type: Number,
      required: true,
    },
    parentComment: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      text: "",
      busy: false,
      isTextValid: null,
    };
  },
  methods: {
    ...mapActions("CostCalculationComments", ["replyComment"]),
    formatDate(date) {
      if (date) {
        return moment
          .utc(String(date))
          .local()
          .format(this.$t("caselist.dateformat"));
      }
      return "";
    },
    validateComment() {
      if (this.text.length == 0 || this.text.length > 2000) {
        this.isTextValid = false;
        return;
      }

      this.isTextValid = true;
      this.createComment();
    },
    createComment() {
      this.busy = true;
      this.replyComment({
        idCostCalculation: this.idCostCalculation,
        comment: this.text,
        previousComment: this.parentComment.idComment,
      })
        .then(() => {
          this.$bvModal.hide("reply-comment-modal");
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
    cancelComment() {
      this.$bvModal.hide("reply-comment-modal");
    },
    clearComment() {
      this.text = "";
      this.busy = false;
      this.isTextValid = null;
    },
  },
};
</script>
