var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"my-2 pr-0",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-card-sub-title',{staticClass:"mb-1"},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(`${_vm.comment.createdByName}, ${_vm.formatDate(_vm.comment.createdDate)}:`)+" ")]),(_vm.canEdit)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(
              _vm.costCalculationStatus != 2 &&
              _vm.comment.childrenComments.length == 0
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":"primary","size":"sm","title":_vm.$t('comments.reply')},on:{"click":function($event){return _vm.reply(_vm.comment)}}},[_c('b-icon-reply-fill',{attrs:{"flip-h":""}})],1):_vm._e(),(
              _vm.costCalculationStatus != 2 &&
              (_vm.UserName === _vm.comment.createdBy || _vm.IsAdminCustomer)
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}},{name:"b-modal",rawName:"v-b-modal",value:('confirm-comment-delete-' + _vm.comment.idComment),expression:"'confirm-comment-delete-' + comment.idComment"}],staticClass:"mx-1",attrs:{"variant":"primary","size":"sm","title":_vm.$t('general.delete')}},[_c('b-icon-trash')],1):_vm._e(),(
              _vm.costCalculationStatus != 2 &&
              (_vm.UserName === _vm.comment.createdBy || _vm.IsAdminCustomer)
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":_vm.comment.isResolved ? 'success' : 'primary',"size":"sm","title":_vm.$t('comments.resolve'),"disabled":_vm.comment.isResolved},on:{"click":_vm.resolve}},[_c('b-icon-check-square-fill')],1):_vm._e()],1):_vm._e()],1)],1),_c('div',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.comment.comment))])])],1),_c('b-card-footer',_vm._l((_vm.comment.childrenComments),function(child,index){return _c('b-card',{key:child.idComment,staticClass:"mt-2",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"px-2"},[_c('b-card-sub-title',{staticClass:"mb-1"},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(`${child.createdByName}, ${_vm.formatDate(child.createdDate)}:`)+" ")]),(_vm.canEdit)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[(_vm.costCalculationStatus != 2 && index == 0)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":"primary","size":"sm","title":_vm.$t('comments.reply')},on:{"click":function($event){return _vm.reply(child)}}},[_c('b-icon-reply-fill',{attrs:{"flip-h":""}})],1):_vm._e(),(
                  _vm.costCalculationStatus != 2 &&
                  (_vm.UserName === child.createdBy || _vm.IsAdminCustomer)
                )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mx-1",attrs:{"variant":"primary","size":"sm","title":_vm.$t('general.delete')},on:{"click":function($event){return _vm.askRemoveChild(child.idComment)}}},[_c('b-icon-trash')],1):_vm._e()],1):_vm._e()],1)],1),_c('div',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(child.comment))])])],1)],1)}),1),_c('Confirm',{attrs:{"id":'confirm-comment-delete-' + _vm.comment.idComment,"title":_vm.$t('views.caseTemplateEdition.delete-template'),"message":_vm.$t('comments.delete-warning'),"variantHeader":"danger","variantText":"light"},on:{"onOk":_vm.remove}}),_c('Confirm',{attrs:{"id":'confirm-child-comment-delete-' + _vm.comment.idComment,"title":_vm.$t('views.caseTemplateEdition.delete-template'),"message":_vm.$t('comments.delete-warning'),"variantHeader":"danger","variantText":"light"},on:{"onOk":_vm.removeChild}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }