<template>
  <b-button
    :id="baseId + '-button'"
    variant="primary"
    size="sm"
    :title="$t('Home.AddCase')"
    v-b-tooltip.hover
    v-b-modal="baseId + '-modal'"
  >
    <b-icon icon="plus" />
    <b-modal
      :id="baseId + '-modal'"
      :title="$t('views.cases.newCase')"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-form-group
        :label="$t('views.cases.organizationUnit')"
        :label-for="baseId + '-organizational-unit'"
        :invalid-feedback="$t('views.cases.organizationUnitRequired')"
        label-cols="4"
        :state="state.organizationalUnit"
      >
        <b-form-select
          :id="baseId + '-organizational-unit'"
          required
          v-model="form.organizationalUnit"
          :state="state.organizationalUnit"
          @change="showInfo"
        >
          <template #first>
            <b-form-select-option :value="null" disabled style="opacity: 0.5">{{
              $t("views.cases.organizationUnitInstructions")
            }}</b-form-select-option>
          </template>
          <b-form-select-option
            v-for="(ou, index) in organizationalUnits.organizationalUnits"
            v-bind:key="index"
            :value="ou"
            >{{ ou.nameOrganizationalUnit }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>

      <div v-if="form.organizationalUnit">
        <b-form-group
          :label="$t('views.cases.caseTemplate')"
          label-for="select-1"
          :invalid-feedback="$t('views.cases.caseTemplateRequired')"
          label-cols="4"
          :state="state.caseTemplate"
        >
          <b-form-select
            v-model="form.caseTemplate"
            required
            :state="state.caseTemplate"
            @change="getTemplateInfo"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
                style="opacity: 0.5"
                >{{
                  $t("views.cases.caseTemplateInstructions")
                }}</b-form-select-option
              >
            </template>
            <b-form-select-option
              id="select-1"
              v-for="(caseTemplate, index) in caseTemplates"
              v-bind:key="index"
              :value="caseTemplate.idCaseTemplate"
              >{{ caseTemplate.caseTemplateName }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <div v-if="form.caseTemplate">
          <div v-if="loadingFields">
            <simple-loader />
          </div>
          <div v-else>
            <b-form-group
              :label="$t('HeaderCase.StudyType')"
              label-for="select-study"
              :invalid-feedback="$t('views.cases.studyTypeRequired')"
              label-cols="4"
              :state="state.studyType"
            >
              <b-form-select
                v-model="form.idCondition"
                :options="conditions"
                value-field="idCondition"
                text-field="sumlageName"
                :state="state.studyType"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{
                    $t("views.cases.studyTypeInstructions")
                  }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('views.cases.name')"
              label-for="input-name-1"
              :invalid-feedback="caseNameFeedback"
              label-cols="4"
              :state="state.caseName"
            >
              <b-form-input
                :placeholder="$t('views.cases.nameInstructions')"
                v-model="form.name"
                id="input-name-1"
                required
                maxlength="100"
                trim
                :formatter="formatter"
                :state="state.caseName"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('views.cases.duration')"
              label-for="input-duration-1"
              :invalid-feedback="$t('views.cases.durationRequired')"
              label-cols="4"
              :state="state.duration"
            >
              <b-form-input
                :placeholder="$t('views.cases.durationInMonths')"
                v-model="form.duration"
                id="input-duration-1"
                required
                type="number"
                :number="true"
                trim
                @change="verifyNegativeValues"
                min="0"
                :state="state.duration"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-for="(field, index) in form.fields"
              v-bind:key="'field_' + field.idCaseField"
              :label="field.title"
              :invalid-feedback="'Required'"
              label-cols="4"
              :state="state.fields[index]"
            >
              <b-row>
                <b-col cols="9">
                  <b-form-input
                    :placeholder="field.placeholder"
                    v-model="field.fieldValue"
                    :id="'field_' + field.idCaseField"
                    :required="field.mandatory"
                    trim
                    :state="state.fields[index]"
                  />
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    v-if="!field.mandatory"
                    size="sm"
                    @click="deleteField(field)"
                  >
                    <b-icon-trash />
                  </b-button>
                  <b-icon v-else scale="1.2" icon="lock-fill" />
                </b-col>
              </b-row>
            </b-form-group>
          </div>
        </div>
      </div>
      <b-list-group-item v-if="busy" class="text-center align-middle">
        <simple-loader />
      </b-list-group-item>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button
            @click="$bvModal.hide(baseId + '-modal')"
            :disabled="busy"
            >{{ $t("general.cancel") }}</b-button
          ><b-button variant="primary" @click="onOK" :disabled="busy">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import simpleLoader from "../../general/simple-loader.vue";

const initForm = {
  organizationalUnit: null,
  name: null,
  duration: null,
  caseTemplate: null,
  idCondition: null,
  fields: [],
};

const initialState = {
  organizationalUnit: null,
  caseTemplate: null,
  caseName: null,
  studyType: null,
  duration: null,
  fields: [],
};

export default {
  components: { simpleLoader },
  data() {
    return {
      form: { ...initForm },
      state: { ...initialState },
      loadingFields: false,
      busy: false,
      baseId: "create-cost-calculation",
      caseNameFeedback: this.$t("views.cases.nameRequired"),
      caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
    };
  },
  computed: {
    ...mapGetters("Cases", ["caseTemplates", "organizationalUnits"]),
    ...mapGetters("Conditions", ["conditions"]),
    ...mapGetters("CaseTemplates", ["caseTemplateFields"]),
  },
  methods: {
    ...mapActions("Cases", ["getCaseTemplates", "getCaseTemplate"]),
    ...mapActions("Conditions", ["getConditions"]),
    ...mapActions("CaseTemplates", ["getCaseTemplateFields"]),
    formatter(value) {
      if (value.match(this.caseNameRegex)) {
        this.caseNameFeedback = this.$t("views.cases.notAllowedSymbols");
        this.state.caseName = false;
      } else {
        this.state.caseName = null;
      }
      return value.replace(this.caseNameRegex, "");
    },
    hideModal() {
      this.resetModal();
      this.$bvModal.hide(this.baseId + "-modal");
    },
    verifyNegativeValues() {
      if (this.form.duration < 0) {
        this.form.duration = 0;
      }
    },
    resetModal() {
      this.form = { ...initForm };
      this.state = { ...initialState };
      this.busyNewCase = false;
    },
    checkFormValidity() {
      let check =
        (this.state.organizationalUnit =
        this.state.caseName =
        this.state.duration =
        this.state.caseTemplate =
          true);

      for (var indexState in this.state.fields) {
        this.state.fields[indexState] = true;
      }

      if (!this.form.organizationalUnit)
        this.state.organizationalUnit = check = false;
      if (!this.form.name) {
        this.state.caseName = check = false;
        this.caseNameFeedback = this.$t("views.cases.nameRequired");
      }
      if (!this.form.duration || this.form.duration < 0)
        this.state.duration = check = false;
      if (!this.form.caseTemplate) this.state.caseTemplate = check = false;
      if (!this.form.idCondition) this.state.studyType = check = false;

      for (var index in this.form.fields) {
        var currentField = this.form.fields[index];
        if (
          currentField.mandatory &&
          !currentField.fieldValue &&
          !currentField.reviewerRequired
        )
          this.state.fields[index] = check = false;
      }

      return check;
    },
    onOK(event) {
      if (!this.checkFormValidity()) {
        event.preventDefault();
        return;
      }
      this.busyNewCase = true;
      this.$emit("selected", this.form);
    },
    showInfo() {
      this.getCaseTemplates({
        api: this.$api,
        idOrganizationalUnit: this.form.organizationalUnit.idOU,
      });
    },
    getTemplateInfo() {
      this.loadingFields = true;
      this.getCaseTemplateFields({
        idCaseTemplate: this.form.caseTemplate,
      })
        .then(() => {
          this.form.name = this.caseTemplateFields.caseDefaultName;
          this.form.idCondition = this.caseTemplateFields.studyType;
          this.form.duration = this.caseTemplateFields.duration;

          this.form.fields = this.caseTemplateFields.fieldsList.map(
            (field) => ({
              idCaseField: field.idCaseTemplateField,
              title: field.fieldTitle,
              fieldValue: "",
              placeholder: field.fieldValue,
              mandatory: field.mandatory,
              sequence: field.sequence,
              reviewerRequired: field.reviewerRequired,
            })
          );

          this.form.fields.sort((a, b) => a.sequence - b.sequence);

          this.state.fields = [];
          for (var step = 0; step < this.form.fields.length; step++) {
            this.state.fields.push(null);
          }
        })
        .finally(() => {
          this.loadingFields = false;
        });
    },
    deleteField(field) {
      const index = this.form.fields.indexOf(field);
      if (index !== -1) {
        this.form.fields.splice(index, 1);
        this.state.fields.splice(index, 1);
      }
    },
  },
  mounted() {
    this.busy = true;
    this.getConditions({
      enabled: true,
    }).then(() => {
      this.busy = false;
    });
  },
};
</script>
