<template>
  <b-modal ref="modal" id="change-owner-modal" title="Change Owner">
    {{ "Select New Owner" }}
    <hr />
    <b-form-select v-model="newOwner">
      <b-form-select-option
        v-for="(user, index) in UserList"
        v-bind:key="index"
        :value="user"
        >{{ user.name }}</b-form-select-option
      >
    </b-form-select>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="$bvModal.hide('change-owner-modal')">{{
          $t("general.cancel")
        }}</b-button>
        <b-button variant="primary" @click="ChangeOwner">
          {{ $t("buttons.ChangeOwner") }}
        </b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    UserList: {
      required: true,
      type: Array,
    },
    case: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      newOwner: {},
    };
  },
  methods: {
    ...mapActions("Cases", ["changeUser"]),
    async ChangeOwner() {
      this.$refs["modal"].hide();
      await this.changeUser({
        session: this.$session,
        api: this.$api,
        caseInfo: this.case,
        newOwner: this.newOwner.userName,
        nameOwner: this.newOwner.name,
      });
    },
  },
};
</script>
