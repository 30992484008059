<template>
  <b-card no-body class="my-2 pr-0">
    <b-card-body>
      <b-card-sub-title class="mb-1">
        <b-row>
          <b-col>
            {{
              `${comment.createdByName}, ${formatDate(comment.createdDate)}:`
            }}
          </b-col>
          <b-col cols="4" class="text-right" v-if="canEdit"
            ><b-button
              variant="primary"
              size="sm"
              class="mx-1"
              v-b-tooltip.hover
              :title="$t('comments.reply')"
              @click="reply(comment)"
              v-if="
                costCalculationStatus != 2 &&
                comment.childrenComments.length == 0
              "
              ><b-icon-reply-fill flip-h
            /></b-button>
            <b-button
              variant="primary"
              size="sm"
              class="mx-1"
              v-b-tooltip.hover
              :title="$t('general.delete')"
              v-if="
                costCalculationStatus != 2 &&
                (UserName === comment.createdBy || IsAdminCustomer)
              "
              v-b-modal="'confirm-comment-delete-' + comment.idComment"
              ><b-icon-trash
            /></b-button>
            <b-button
              :variant="comment.isResolved ? 'success' : 'primary'"
              size="sm"
              class="mx-1"
              v-b-tooltip.hover
              :title="$t('comments.resolve')"
              :disabled="comment.isResolved"
              v-if="
                costCalculationStatus != 2 &&
                (UserName === comment.createdBy || IsAdminCustomer)
              "
              @click="resolve"
            >
              <b-icon-check-square-fill />
            </b-button>
          </b-col> </b-row
      ></b-card-sub-title>
      <div>
        <span style="white-space: pre-wrap">{{ comment.comment }}</span>
      </div>
    </b-card-body>
    <b-card-footer>
      <b-card
        v-for="(child, index) in comment.childrenComments"
        v-bind:key="child.idComment"
        no-body
        class="mt-2"
      >
        <b-card-body class="px-2">
          <b-card-sub-title class="mb-1">
            <b-row>
              <b-col>
                {{
                  `${child.createdByName}, ${formatDate(child.createdDate)}:`
                }}
              </b-col>
              <b-col cols="3" class="text-right" v-if="canEdit"
                ><b-button
                  variant="primary"
                  size="sm"
                  class="mx-1"
                  v-b-tooltip.hover
                  :title="$t('comments.reply')"
                  @click="reply(child)"
                  v-if="costCalculationStatus != 2 && index == 0"
                  ><b-icon-reply-fill flip-h
                /></b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  class="mx-1"
                  :title="$t('general.delete')"
                  v-b-tooltip.hover
                  v-if="
                    costCalculationStatus != 2 &&
                    (UserName === child.createdBy || IsAdminCustomer)
                  "
                  @click="askRemoveChild(child.idComment)"
                  ><b-icon-trash
                /></b-button>
              </b-col> </b-row
          ></b-card-sub-title>
          <div>
            <span style="white-space: pre-wrap">{{ child.comment }}</span>
          </div>
        </b-card-body>
      </b-card>
    </b-card-footer>
    <Confirm
      :id="'confirm-comment-delete-' + comment.idComment"
      :title="$t('views.caseTemplateEdition.delete-template')"
      :message="$t('comments.delete-warning')"
      @onOk="remove"
      variantHeader="danger"
      variantText="light"
    />
    <Confirm
      :id="'confirm-child-comment-delete-' + comment.idComment"
      :title="$t('views.caseTemplateEdition.delete-template')"
      :message="$t('comments.delete-warning')"
      @onOk="removeChild"
      variantHeader="danger"
      variantText="light"
    />
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";
import moment from "moment";
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
    costCalculationStatus: {
      type: Number,
      required: true,
    },
  },
  components: {
    Confirm,
  },
  data() {
    return {
      selectedReplyComment: null,
      selectedDeleteId: null,
    };
  },
  methods: {
    ...mapActions("CostCalculationComments", [
      "deleteComment",
      "deleteChildComment",
      "resolveComment",
      "replyComment",
    ]),
    formatDate(date) {
      if (date) {
        return moment
          .utc(String(date))
          .local()
          .format(this.$t("caselist.dateformat"));
      }
      return "";
    },
    reply(replyComment) {
      this.$emit("selectReply", replyComment).catch((error) => {
        this.$bvToast.toast(error, {
          variant: "danger",
          title: this.$t("views.roles.LoadingOrganizationalUnits"),
          autoHideDelay: 5000,
          appendToast: true,
        });
      });
    },
    remove() {
      this.deleteComment({ idComment: this.comment.idComment }).catch(
        (error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      );
    },
    removeChild() {
      this.deleteChildComment({
        idComment: this.selectedDeleteId,
        idParentComment: this.comment.idComment,
      })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.selectedDeleteId = null;
          this.$bvModal.hide(
            "confirm-child-comment-delete-" + this.comment.idComment
          );
        });
    },
    askRemoveChild(idComment) {
      this.selectedDeleteId = idComment;
      this.$bvModal.show(
        "confirm-child-comment-delete-" + this.comment.idComment
      );
    },
    resolve() {
      this.resolveComment({
        idComment: this.comment.idComment,
      }).catch((error) => {
        this.$bvToast.toast(error, {
          variant: "danger",
          title: this.$t("views.roles.LoadingOrganizationalUnits"),
          autoHideDelay: 5000,
          appendToast: true,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      UserName: "GET_USERNAME",
      IsAdminCustomer: "GET_ISADMINCUSTOMER",
      Features: "GET_FEATURES",
    }),
    canEdit() {
      return this.Features.length > 0;
    },
  },
  mounted() {
    this.id = this.baseId + this.idCostCalculation;
    this.selectedReplyComment = null;
  },
};
</script>
