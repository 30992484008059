<template>
  <b-btn
    size="sm"
    variant="primary"
    v-b-modal.modal-duplicate-cost-calculation
    v-b-tooltip.hover
    :title="$t('views.home.duplicateCase')"
    :disabled="!originalCase.idCase"
  >
    <b-icon-files />
    <b-modal
      id="modal-duplicate-cost-calculation"
      ref="modal"
      :title="$t('views.home.duplicateCase')"
      :busy="busy"
      @show="onShow"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay
        :show="busy"
        rounded="sm"
        variant="white"
        spinner-variant="primary"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('views.home.caseName')"
            label-for="name-input"
            :invalid-feedback="$t('views.home.caseNameRequired')"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              :state="nameState"
              required
              :disabled="busy"
            ></b-form-input>
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    originalCase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      nameState: null,
      busy: false,
    };
  },
  methods: {
    ...mapActions("Cases", ["duplicateCase"]),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    onShow() {
      console.log(this.originalCase);
      this.name = this.originalCase.name;
      this.busy = false;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
      this.busy = false;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.busy = true;
      // Push the name to submitted names
      this.duplicateCase({
        session: this.$session,
        api: this.$api,
        idCase: this.originalCase.idCase,
        name: this.name,
      })
        .then((data) => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$router.push({
              name: "Cases",
              params: { idCase: data.idCase },
            });
          });
        })
        .finally(() => {
          this.$bvModal.hide("modal-duplicate-cost-calculation");
          this.busy = false;
        });
    },
  },
};
</script>

<style></style>
