<template>
  <div class="cases">
    <Alert />
    <masterdetail :masterLoading="masterLoading" :detailLoading="detailLoading">
      <template v-slot:master>
        <b-card class="h-100 p-0" no-body>
          <!--Case list header-->
          <b-card-header class="p-1" id="CaseFormHeader">
            <div class="list_cases__header-title">
              <div>
                <h4 class="m-2">
                  {{ $t("views.cases.title") }} ({{ casesList.length }})
                </h4>
              </div>
            </div>
            <!--Sort Menu-->
            <div>
              <b-row>
                <b-col
                  class="mb-1"
                  cols="3"
                  align-self="start"
                  v-if="HideCreateCase"
                >
                  <b-button-toolbar>
                    <b-button-group class="ml-auto">
                      <btn-create-cost-calculation @selected="onNewCase" />
                      <btn-copy-case
                        :originalCase="caseSelectd"
                      /> </b-button-group
                  ></b-button-toolbar>
                </b-col>
                <b-col class="mb-1 text-right" align-self="end">
                  <b-dropdown
                    variant="outline-primary"
                    id="CasesSortBy"
                    class="ml-1"
                    size="sm"
                  >
                    <template #button-content>
                      {{ $t("views.cases.SortBy") }}
                    </template>
                    <b-dropdown-item-button
                      @click="sortedCaselistBy = 'lastModified'"
                      id="CasesSortByLastModified"
                    >
                      <b-icon icon="clock" aria-hidden="true"></b-icon>
                      {{ $t("views.cases.lastModified") }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="sortedCaselistBy = 'ascending'"
                      id="CasesSortByAscending"
                    >
                      <b-icon
                        icon="sort-alpha-down"
                        aria-hidden="true"
                      ></b-icon>
                      {{ $t("views.cases.ascending") }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="sortedCaselistBy = 'descending'"
                      id="CasesSortByDescending"
                    >
                      <b-icon
                        icon="sort-alpha-down-alt"
                        aria-hidden="true"
                      ></b-icon>
                      {{ $t("views.cases.descending") }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <b-checkbox
                    v-model="filtersShown"
                    name="filtersShown-button"
                    button
                    button-variant="outline-primary"
                    class="ml-2"
                    id="CasesFilter"
                    size="sm"
                  >
                    <b-icon icon="funnel" />
                  </b-checkbox>
                </b-col>
              </b-row>
            </div>
            <div v-if="filtersShown">
              <b-form-select
                v-model="OUSelectedFilter"
                :options="OUFilter"
                id="CasesFilterOU"
              ></b-form-select>
              <b-form-select
                v-model="statusSelectedFilter"
                :options="StatusCases"
                id="CasesFilterStatus"
              ></b-form-select>
            </div>
            <b-form inline @submit.prevent>
              <b-input-group class="w-100">
                <b-form-input
                  size="sm"
                  :placeholder="$t('general.search')"
                  v-model="SearchCasesWord"
                  id="CasesSearchInput"
                >
                </b-form-input>
              </b-input-group>
            </b-form>
          </b-card-header>
          <b-card-body
            class="h-100 p-0 overflow-y-auto"
            active-class="primary"
            data-mdb-perfect-scrollbar="true"
          >
            <b-list-group flush id="CasesCaseList">
              <b-list-group-item
                v-for="caseHeader in SearchCases"
                v-bind:key="caseHeader.idCase"
                :id="'CasesCase' + caseHeader.idCase"
                active-class="active"
                :to="{
                  name: 'Cases',
                  params: {
                    idCase: caseHeader.idCase,
                  },
                }"
                class="flex-column align-items-start text-capitalize overflow-hidden"
              >
                <div class="d-flex w-100 justify-content-between">
                  <b-container class="pl-0 pr-0">
                    <b-row>
                      <b-col md="8">
                        <h5
                          v-b-tooltip.hover
                          :title="caseHeader.caseName"
                          class="mb-1 text-break text-truncate"
                        >
                          <strong>{{ caseHeader.caseName }} </strong>
                        </h5>
                      </b-col>
                      <b-col class="pl-0 pr-0 text-right" md="4">
                        <small :class="'status status-' + caseHeader.idStatus">
                          {{ $t("status." + caseHeader.idStatus) }}</small
                        >
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-0">
                    {{ caseHeader.organizationalUnitName }}
                  </p>
                  <small> {{ caseHeader.owner }}</small>
                </div>
                <div class="d-flex w-100 justify-content-between">
                  <em
                    ><small>
                      {{ $t("fields.Home.LastModified") }} :
                      {{ FormatDate(caseHeader.lastModified) }}</small
                    ></em
                  >
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </template>
      <template v-slot:detail>
        <b-card v-if="!caseSelectd.idCase" class="h-100" id="CasesEmptyCase">
          <b-alert show> {{ $t("views.cases.selectCase") }}</b-alert>
        </b-card>
        <b-card v-else class="h-100" no-body id="CasesCaseDetail">
          <b-card-header>
            <div class="flex-column align-items-start">
              <div class="d-flex w-1000 justify-content-between">
                <b-container class="pl-0 pr-0">
                  <b-row>
                    <b-col md="8">
                      <h4 class="mb-1 text-break">
                        <strong>{{ caseSelectd.name }} </strong>
                      </h4>
                    </b-col>
                    <b-col md="4">
                      <small>
                        <b-button-toolbar>
                          <b-button-group class="ml-auto">
                            <feedback-modal
                              :idCostCalculation="caseSelectd.idCase"
                              :hasUnresolved="caseSelectd.hasOpenCommentThreads"
                              :hasComments="caseSelectd.hasComments"
                              :costCalculationName="caseSelectd.name"
                              :costCalculationStatus="caseSelectd.idStatus"
                              :costCalculationOwner="caseSelectd.owner"
                            />
                            <b-button
                              variant="primary"
                              v-b-tooltip.hover
                              size="sm"
                              :title="$t('Home.displayHeaderCase')"
                              :to="{
                                name: 'Case',
                                params: {
                                  idCase: caseSelectd.idCase,
                                  idOU: caseSelectd.idOrganizationalUnit,
                                },
                                hash: '#display',
                              }"
                              id="CasesShowCase"
                            >
                              <view-icon />
                            </b-button>
                            <b-button
                              variant="primary"
                              v-b-tooltip.hover
                              size="sm"
                              :title="$t('Home.RemoveCase')"
                              :disabled="!UserRole.isAdminCustomer && isClosed"
                              @click="onRemoveHeaderCase"
                              id="CasesRemoveCase"
                            >
                              <b-icon-trash />
                            </b-button>
                            <export-option
                              :idCalculation="caseSelectd.idCase"
                              idModal="cost-calculation-export-modal"
                              :sorting="sortedSubcaseId"
                            />
                            <div v-if="Name === caseSelectd.owner">
                              <b-button
                                variant="primary"
                                size="sm"
                                v-b-tooltip.hover
                                :title="$t('HeaderCase.UserCollaboration')"
                                :disabled="!caseSelectd"
                                :to="{
                                  name: 'UserCollaboration',
                                  params: { idCase: caseSelectd.idCase },
                                }"
                                id="CasesUserCollab"
                              >
                                <b-icon-people />
                              </b-button>
                            </div>
                            <b-button
                              variant="primary"
                              v-b-tooltip.hover
                              size="sm"
                              :title="$t('Home.PrintCase')"
                              @click="openPrint"
                              id="CasesPrintCase"
                            >
                              <b-icon-printer />
                            </b-button>
                          </b-button-group> </b-button-toolbar
                      ></small>
                    </b-col> </b-row
                ></b-container>
              </div>
              <b-container class="pl-0 pr-0">
                <b-row>
                  <b-col md="7">
                    <h5 class="mb-1 text-capitalize w-75">
                      <strong>{{ caseSelectd.organizationalUnitName }} </strong>
                    </h5></b-col
                  >
                  <b-col md="2" class="ml-4 pl-0 pr-0">
                    <strong>{{ $t("fields.Home.Status") + ": " }}</strong>
                    <span :class="'status status-' + caseSelectd.idStatus">{{
                      $t("status." + caseSelectd.idStatus)
                    }}</span></b-col
                  >
                </b-row>
                <b-row>
                  <b-col md="7">
                    <strong>{{ $t("fields.Home.CreatedOn") }}:</strong>
                    {{ FormatDate(caseSelectd.createdOn) }}
                  </b-col>
                  <b-col md="2" class="ml-4 pl-0 pr-0">
                    <strong>{{ $t("fields.Home.CreatedBy") }}:</strong>
                    {{ caseSelectd.createdBy }}</b-col
                  >
                </b-row>
                <b-row>
                  <b-col md="7">
                    <strong>{{ $t("fields.Home.LastModified") }}: </strong>
                    {{ FormatDate(caseSelectd.lastModified) }}
                    <div v-if="caseSelectd.closedOn">
                      <strong>{{ $t("fields.Home.ClosedOn") }}: </strong>
                      {{ FormatDate(caseSelectd.closedOn) }}
                    </div>
                  </b-col>
                  <b-col md="2" class="ml-4 pl-0 pr-0">
                    <div v-if="caseSelectd.changeOwner">
                      <b-link
                        @click="OpenChangeOwner(caseSelectd)"
                        id="CasesChangeOwner"
                      >
                        <strong>{{ $t("fields.Home.Owner") }}: </strong
                        >{{ caseSelectd.owner }}</b-link
                      >
                    </div>
                    <div>
                      <strong>{{ $t("fields.Home.IdCase") }}: </strong>
                      STD-{{ caseSelectd.idCase }}
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-card-header>
          <b-card-body class="p-0">
            <b-form inline class="m-3" @submit.prevent>
              <b-button-toolbar>
                <b-button-group class="ml-auto">
                  <b-button
                    :disabled="!subcase"
                    variant="primary"
                    v-b-tooltip.hover
                    size="sm"
                    :title="$t('Home.DisplaySubcase')"
                    @click="OnEditSubcase(subcase.idSubcase)"
                    id="CasesDisplaySubcase"
                  >
                    <view-icon />
                  </b-button>
                  <b-button
                    variant="primary"
                    v-b-tooltip.hover
                    size="sm"
                    :title="$t('Home.RemoveSubcase')"
                    :disabled="!subcase || subcase.blocked"
                    @click="onRemove"
                    id="CasesRemoveSubcase"
                  >
                    <b-icon-trash />
                  </b-button>
                  <export-option
                    idModal="partial-cost-calculation-export-modal"
                    :idCalculation="subcase ? subcase.idSubcase : 0"
                    :isPartial="true"
                    :disabled="!subcase"
                  />
                  <duplicate-subcase :subcase="subcase" />
                </b-button-group>
                <b-button-group class="mx-1">
                  <b-button
                    :disabled="isClosed"
                    variant="primary"
                    v-b-tooltip.hover
                    size="sm"
                    :title="$t('Home.AddSubcase')"
                    v-b-modal="'select-masterform'"
                    id="CasesAddSubcase"
                  >
                    <b-icon-file-plus />
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <b-input-group class="ml-auto">
                <b-form-input
                  :placeholder="$t('general.search')"
                  size="sm"
                  v-model="SearchSubCasesWord"
                  class="list_subcases__header-search_bar"
                  id="CasesSubcaseSearch"
                >
                </b-form-input>

                <template #append>
                  <b-dropdown variant="primary" id="CasesSubcasesSortBy">
                    <template #button-content>
                      <b-icon
                        icon="sort-alpha-down"
                        aria-hidden="true"
                        class="list_cases__header-btn-sort"
                      ></b-icon>
                    </template>
                    <b-dropdown-item-button
                      @click="changeSubcaseSort(0, 'default')"
                      id="CasesSubcasesSortByDefault"
                    >
                      <b-icon
                        icon="sort-numeric-down"
                        aria-hidden="true"
                      ></b-icon>
                      {{ $t("views.cases.default") }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="changeSubcaseSort(1, 'lastModified')"
                      id="CasesSubcasesSortByLastModified"
                    >
                      <b-icon icon="clock" aria-hidden="true"></b-icon>
                      {{ $t("views.cases.lastModified") }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="changeSubcaseSort(2, 'ascending')"
                      id="CasesSubcasesSortByAscending"
                    >
                      <b-icon
                        icon="sort-alpha-down"
                        aria-hidden="true"
                      ></b-icon>
                      {{ $t("views.cases.ascending") }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="changeSubcaseSort(3, 'descending')"
                      id="CasesSubcasesSortByDescending"
                    >
                      <b-icon
                        icon="sort-alpha-down-alt"
                        aria-hidden="true"
                      ></b-icon>
                      {{ $t("views.cases.descending") }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <b-checkbox
                    v-model="subcaseFiltersShown"
                    name="subcaseFiltersShown-button"
                    button
                    button-variant="primary"
                    class="ml-2"
                    id="CasesSubcaseFilter"
                  >
                    <b-icon icon="funnel" />
                  </b-checkbox>
                  <div v-if="subcaseFiltersShown">
                    <b-form-select
                      v-model="subcaseStatusSelectedFilter"
                      :options="StatusSubcases"
                      id="CasesSubcasesFilterStatus"
                    ></b-form-select>
                  </div>
                </template>
              </b-input-group>
            </b-form>
            <div v-if="!caseSelectd.subcases.length">
              <b-alert show class="text-center">
                {{ $t("Home.SubcasesNotFound") }}</b-alert
              >
            </div>
            <b-list-group v-else flush id="CasesSubcasesList">
              <b-list-group-item
                v-for="subcase in SearchSubCases"
                v-bind:key="subcase.idSubcase"
                :active="subcase.active"
                @click="onSubcaseClick(subcase)"
                class="flex-column align-items-start"
                :id="'CasesSubcase' + subcase.idSubcase"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    <strong>{{ subcase.name }} </strong>
                    <b-icon-lock-fill v-if="subcase.blocked" />
                  </h5>
                  <small> {{ $t("status." + subcase.idStatus) }}</small>
                </div>
                <div class="d-flex w-100 justify-content-between">
                  <small> {{ subcase.owner }}</small>
                  <small>
                    {{
                      subcase.masterForm == null || subcase.masterForm == ""
                        ? "N/A"
                        : subcase.masterForm
                    }}
                  </small>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </template>
    </masterdetail>
    <NewOwnerModal
      ref="change-owner-modal"
      :UserList="userList"
      :case="changeCase"
    />
    <SubcaseCreationModal
      :idOrganizationalUnit="caseSelectd.idOrganizationalUnit"
      @selected="OnNewSubcase"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../router";
import NewOwnerModal from "@/components/SelectNewOwner";
import SubcaseCreationModal from "@/components/SubcaseCreationModal";
import DuplicateSubcase from "@/components/Subcase/DuplicateSubcase";
import Alert from "@/components/general/alert";
import masterdetail from "@/components/layout/master-detail.vue";
import moment from "moment";
import ViewIcon from "@/components/general/icons/View.vue";
import ExportOption from "@/components/cost-calculation/export-option.vue";
import CopyCase from "@/components/Cases/CopyCase.vue";
import CreateCostCalculationOption from "../components/cost-calculation/document/create-option.vue";
import FeedbackModal from "../components/cost-calculation/reviewers-feedback/feedback-modal-option.vue";

export default {
  components: {
    NewOwnerModal,
    masterdetail,
    SubcaseCreationModal,
    Alert,
    "duplicate-subcase": DuplicateSubcase,
    "view-icon": ViewIcon,
    ExportOption,
    "btn-copy-case": CopyCase,
    "btn-create-cost-calculation": CreateCostCalculationOption,
    FeedbackModal,
  },
  data() {
    return {
      filtersShown: false,
      subcaseFiltersShown: false,
      selected: [],
      statusSelectedFilter: null,
      subcaseStatusSelectedFilter: null,
      OUSelectedFilter: null,
      showExport: false,
      showOUs: false,
      changeCase: {},
      isClosed: false,
      users: [],
      subcase: null,
      SearchCasesWord: "",
      SearchSubCasesWord: "",
      masterLoading: false,
      detailLoading: false,
      exportCase: {
        idcase: null,
        idsubcase: null,
        ou: null,
      },
      showDismissibleAlert: false,
      sortedCaselistBy: "lastModified",
      sortedSubcaselistBy: "default",
      sortedSubcaseId: 0,
    };
  },
  watch: {
    $route(newValue) {
      const { idCase } = newValue.params;
      if (!idCase) return;
      this.SearchSubCasesWord = "";
      this.detailLoading = true;
      this.getCaseSelectd({
        api: this.$api,
        idCase: idCase,
      })
        .then((x) => {
          this.isClosed = x.data.status === "Closed";
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },
    filtersShown(value) {
      if (!value) {
        this.statusSelectedFilter = null;
        this.OUSelectedFilter = null;
      }
    },
    subcaseFiltersShown(value) {
      if (!value) {
        this.subcaseStatusSelectedFilter = null;
      }
    },
  },
  methods: {
    ...mapActions("Cases", [
      "fetchCases",
      "deleteCases",
      "fetchOUs",
      "fetchUserList",
      "getCaseSelectd",
      "postCase",
      "postSubcase",
    ]),
    ...mapActions("Subcases", ["DeleteSubcase"]),
    ...mapActions({ hideMessage: "DELETE_MESSAGE" }),
    onSubcaseClick(subcase) {
      this.subcase = subcase;
      this.subcase.active = true;
      if (this._subcase) this._subcase.active = false;
      this._subcase = this.subcase;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    changeSubcaseSort(sortId, sortName) {
      this.sortedSubcaseId = sortId;
      this.sortedSubcaselistBy = sortName;
    },
    onEdit() {
      if (this.selected !== null) {
        router.push({
          name: "Case",
          params: {
            caseId: {
              idCase: this.selected[0].idCase,
              idOrganizationalUnit: this.selected[0].idOrganizationalUnit,
              newCase: false,
            },
          },
        });
      }
    },
    async OpenChangeOwner(caseItem) {
      this.changeCase = caseItem;
      await this.fetchUserList({
        session: this.$session,
        api: this.$api,
        idOU: caseItem.idOrganizationalUnit,
      });
      this.$refs["change-owner-modal"].$refs["modal"].show();
    },
    onNewCase({
      organizationalUnit,
      name,
      duration,
      caseTemplate,
      idCondition,
      fields,
    }) {
      this.masterLoading = true;
      this.postCase({
        idOrganizationalUnit: organizationalUnit.idOU,
        caseName: name,
        duration: duration,
        session: this.$session,
        api: this.$api,
        idCaseTemplate: caseTemplate,
        idCondition,
        caseFields: fields,
      })
        .then(() => {
          this.fetchCases({
            session: this.$session,
            api: this.$api,
          });
        })
        .finally(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide("select-ou-modal");
          });
          this.masterLoading = false;
        });
    },
    onRemove() {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.prompts.DeleteCase"), {
          title: this.$t("messages.titles.DeleteCase"),
        })
        .then((answer) => {
          if (!answer) return;
          this.DeleteSubcase({
            session: this.$session,
            api: this.$api,
            idSubcase: this.subcase.idSubcase,
          });
        });
    },
    onRemoveHeaderCase() {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.prompts.DeleteCase"), {
          title: this.$t("messages.titles.DeleteCase"),
        })
        .then((answer) => {
          if (!answer) return;
          this.deleteCases({
            session: this.$session,
            api: this.$api,
            idCase: this.$route.params.idCase,
            idOrganizationalUnit: this.$route.params.idOU,
          });
          router.replace({ name: "CasesList" });
        });
    },
    FormatDate(date) {
      if (date) {
        return moment
          .utc(String(date))
          .local()
          .format(this.$t("caselist.dateformat"));
      }
      return "";
    },
    OnNewSubcase(form) {
      this.postSubcase({
        name: form.name,
        idCase: this.caseSelectd.idCase,
        session: this.$session,
        api: this.$api,
        idMasterForm: form.masterForm.idMasterForm,
      }).then((data) => {
        const idSubcase = data.idSubcase;
        router.push({
          name: "Subcase",
          params: {
            idSubcase: idSubcase,
          },
        });
      });
    },
    OnEditSubcase(idSubcase) {
      router.push({
        name: "Subcase",
        params: {
          idSubcase: idSubcase,
          nameCase: this.caseSelectd.name,
        },
      });
    },
    async openPrint() {
      await this.$api
        .axios({
          url:
            "/cases/export/" +
            this.caseSelectd.idCase +
            "?format=PDFWithoutComments&subcaseSorting=" +
            this.sortedSubcaseId,
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          let w = window.open(url);
          w.print();
        });
    },
    onDemisse() {
      this.hideMessage();
    },
    sortCasesList(arrayObject) {
      let lisOfCasesSorted = arrayObject;

      if (this.sortedCaselistBy === "lastModified") {
        lisOfCasesSorted.sort((a, b) => {
          let dateA = new Date(a.lastModified).getTime();
          let dateB = new Date(b.lastModified).getTime();
          return dateA < dateB ? 1 : -1;
        });
      }
      if (this.sortedCaselistBy === "ascending") {
        lisOfCasesSorted.sort((a, b) =>
          a.caseName !== b.caseName
            ? a.caseName.toLowerCase() < b.caseName.toLowerCase()
              ? -1
              : 1
            : 0
        );
      }
      if (this.sortedCaselistBy === "descending") {
        lisOfCasesSorted.sort((a, b) =>
          a.caseName !== b.caseName
            ? a.caseName.toLowerCase() > b.caseName.toLowerCase()
              ? -1
              : 1
            : 0
        );
      }
      return lisOfCasesSorted;
    },
    sortSubcasesList(arrayObject) {
      let lisOfCasesSorted = arrayObject;

      if (this.sortedSubcaselistBy === "default") {
        lisOfCasesSorted.sort((a, b) =>
          a.idSubcase !== b.idSubcase ? (a.idSubcase > b.idSubcase ? 1 : -1) : 0
        );
      }
      if (this.sortedSubcaselistBy === "lastModified") {
        lisOfCasesSorted.sort((a, b) => {
          let dateA = new Date(a.lastModified).getTime();
          let dateB = new Date(b.lastModified).getTime();
          return dateA < dateB ? 1 : -1;
        });
      }
      if (this.sortedSubcaselistBy === "ascending") {
        lisOfCasesSorted.sort((a, b) =>
          a.name !== b.name
            ? a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : 1
            : 0
        );
      }
      if (this.sortedSubcaselistBy === "descending") {
        lisOfCasesSorted.sort((a, b) =>
          a.name !== b.name
            ? a.name.toLowerCase() > b.name.toLowerCase()
              ? -1
              : 1
            : 0
        );
      }
      return lisOfCasesSorted;
    },
  },
  computed: {
    ...mapGetters({
      caseStatus: "getCaseStatus",
      subcaseStatus: "getSubcaseStatus",
      masterFormStatus: "getMasterFormStatus",
      message: "GET_MESSAGE",
      Name: "GET_NAME",
      UserRole: "GET_USERROLES",
    }),
    ...mapGetters("Cases", [
      "casesList",
      "loading",
      "organizationalUnits",
      "isAdmin",
      "userList",
      "caseSelectd",
    ]),
    HideCreateCase() {
      if (this.UserRole.isAdmin || this.UserRole.isReviewer) {
        return false;
      } else return true;
    },
    OUFilter() {
      let OU = [
        { value: null, text: this.$t("views.home.OrganizationalUnits") },
      ];
      if (this.organizationalUnits.organizationalUnits) {
        this.organizationalUnits.organizationalUnits.forEach((element) => {
          OU.push({
            value: element.idOU,
            text: element.nameOrganizationalUnit,
          });
        });
      }
      return OU;
    },
    StatusCases() {
      const that = this;
      return this.caseStatus
        ? this.caseStatus.map(function (old) {
            return {
              value: old.value,
              text: that.$t(old.text),
            };
          })
        : this.caseStatus;
    },
    StatusSubcases() {
      const that = this;
      return this.subcaseStatus
        ? this.subcaseStatus.map(function (old) {
            return {
              value: old.value,
              text: that.$t(old.text),
            };
          })
        : this.subcaseStatus;
    },
    SearchCases() {
      let byStatusCases = this.statusSelectedFilter
        ? this.casesList.filter((x) => x.status === this.statusSelectedFilter)
        : this.casesList;

      let byOUCases = this.OUSelectedFilter
        ? byStatusCases.filter(
            (x) => x.idOrganizationalUnit === this.OUSelectedFilter
          )
        : byStatusCases;

      let search = byOUCases.filter((x) =>
        x.caseName
          .toLocaleLowerCase()
          .includes(this.SearchCasesWord.toLocaleLowerCase())
      );
      if (search.length === 0) {
        search = byOUCases.filter((x) =>
          x.owner
            .toLocaleLowerCase()
            .includes(this.SearchCasesWord.toLocaleLowerCase())
        );
      }
      return this.statusSelectedFilter ||
        this.OUSelectedFilter ||
        this.SearchCasesWord !== ""
        ? this.sortCasesList(search)
        : this.sortCasesList(this.casesList);
      //return search.length > 0 ? search : this.sortCasesList(this.casesList);
    },
    SearchSubCases() {
      let byStatusCases = this.subcaseStatusSelectedFilter
        ? this.caseSelectd.subcases.filter(
            (x) => x.status === this.subcaseStatusSelectedFilter
          )
        : this.caseSelectd.subcases;

      let search = byStatusCases.filter((x) =>
        x.name
          .toLocaleLowerCase()
          .includes(this.SearchSubCasesWord.toLocaleLowerCase())
      );

      return this.subcaseStatusSelectedFilter || this.SearchSubCasesWord
        ? this.sortSubcasesList(search)
        : this.sortSubcasesList(this.caseSelectd.subcases);
    },
    fields() {
      return [
        {
          key: "caseName",
          sortable: true,
          label: this.$t("fields.Home.CaseName"),
        },
        {
          key: "organizationalUnitName",
          sortable: true,
          label: this.$t("fields.Home.OrganizationalUnit"),
        },
        {
          key: "owner",
          sortable: true,
          label: this.$t("fields.Home.Owner"),
        },
        {
          key: "createdBy",
          sortable: true,
          label: this.$t("fields.Home.CreatedBy"),
        },
        {
          key: "status",
          sortable: true,
          label: this.$t("fields.Home.Status"),
        },
        {
          key: "createdOn",
          sortable: true,
          label: this.$t("fields.Home.CreatedOn"),
        },
        {
          key: "lastModified",
          sortable: true,
          label: this.$t("fields.Home.LastModified"),
        },
        {
          key: "closedOn",
          sortable: true,
          label: this.$t("fields.Home.ClosedOn"),
        },
      ];
    },
    exportCasedata() {
      return this.exportCase;
    },
  },
  created() {
    if (
      this.caseSelectd &&
      this.caseSelectd.idOrganizationalUnit != 0 &&
      this.caseSelectd.idCase != 0
    ) {
      this.$router.push({
        name: "Cases",
        params: {
          idCase: this.caseSelectd.idCase,
        },
      });
    }

    this.masterLoading = true;
    this.fetchCases({
      session: this.$session,
      api: this.$api,
    }).finally(() => {
      this.masterLoading = false;
    });

    this.fetchOUs({
      session: this.$session,
      api: this.$api,
    });

    const { idCase } = this.$route.params;
    if (!idCase) return;

    this.detailLoading = true;
    this.getCaseSelectd({
      api: this.$api,
      idCase: idCase,
    })
      .then((x) => {
        this.isClosed = x.data.status === "Closed";
      })
      .finally(() => {
        this.detailLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.cases {
  width: 100%;
  height: 100%;
}
.list_cases__header-title {
  display: flex;
}

.list_cases__header-search_bar {
  border-radius: 20px;
  box-shadow: 0px 3px 2px -3px black;
}
.list_subcases__header-search_bar {
  border-radius: 20px;
  box-shadow: 0px 3px 2px -3px black;
  height: 35px;
}
.overflow-y-auto {
  overflow-y: auto;
}
</style>
