<template>
  <b-modal
    :title="$t('comments.new-comment')"
    id="new-comment-modal"
    @show="clearComment"
  >
    <b-form-group
      :state="isTextValid"
      :invalid-feedback="$t('comments.length-feedback')"
    >
      <b-textarea v-model="text" required :state="isTextValid" />
    </b-form-group>
    <template v-slot:modal-footer>
      <slot name="footer">
        <b-button @click="cancelComment" :disabled="busy">{{
          $t("general.cancel")
        }}</b-button
        ><b-button
          variant="primary"
          @click="validateComment"
          :disabled="busy"
          >{{ $t("general.ok") }}</b-button
        ></slot
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    idCostCalculation: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      text: "",
      busy: false,
      isTextValid: null,
    };
  },
  methods: {
    ...mapActions("CostCalculationComments", ["postComment"]),
    validateComment() {
      if (this.text.length == 0 || this.text.length > 2000) {
        this.isTextValid = false;
        return;
      }

      this.isTextValid = true;
      this.createComment();
    },
    createComment() {
      this.busy = true;
      this.postComment({
        idCostCalculation: this.idCostCalculation,
        comment: this.text,
      })
        .then(() => {
          this.$bvModal.hide("new-comment-modal");
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
    cancelComment() {
      this.$bvModal.hide("new-comment-modal");
    },
    clearComment() {
      this.text = "";
      this.busy = false;
      this.isTextValid = null;
    },
  },
};
</script>
